// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBH02Gwao6N1SO_NFq5j0n4x5AkMeVPmo0",
  authDomain: "mobiliza2024.firebaseapp.com",
  projectId: "mobiliza2024",
  storageBucket: "mobiliza2024.appspot.com",
  messagingSenderId: "115970211024",
  appId: "1:115970211024:web:730ee47908b1f0d21db2d5",
  measurementId: "G-KJ6B8GJ5V0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
