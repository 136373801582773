import "animate.css/animate.min.css";
import "./App.css";
import "./firebaseConfig";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";

const HomeV2 = React.lazy(() => import("./components/HomeV2"));
const SuccessV2 = React.lazy(() => import("./components/SuccessV2"));
const PricesV2 = React.lazy(() => import("./components/PricesV2"));

function App() {
  return (
    <div className="app w-full flex flex-col items-center">
      <BrowserRouter>
        <Suspense>
          <Routes>
            <Route path="prices" element={<PricesV2 />} />
            <Route path="success" element={<SuccessV2 />} />
            <Route path="*" element={<HomeV2 />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
